import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  type BrandData,
  type BrandsComponent,
  type ContentModule,
  type FourGridBanners,
  type HomePage20ContentModules,
  type TwoColumnBanners,
  type TwoColumnGrid,
  type UspTeaser,
} from "@xxl/content-api";
import {
  componentIndicesOnHomepageWithHighPrio,
  isProduct,
} from "react-app/src/components/Banners/Shared/BannerContent/BannerContent.helper";
import type { BrandsDataProps } from "react-app/src/components/Search/SearchState";

const BRANDS_MAX_AMOUNT = {
  mobile: 3,
  tablet: 4,
  desktop: 6,
};

export const MODULE_TYPES = {
  BRANDS: "Brand",
  CAMPAIGN_CAROUSEL: "CampaignCarouselComponent",
  CATEGORIES: "Categories",
  FULL_GRID: "FullGridComponent",
  GUIDES: "guideCarousel",
  HALF_GRID: "HalfGridComponent",
  HIGHLIGHTED_CATEGORIES: "highlightedCategoriesComponent",
  CONTENT_PRODUCT_CAROUSEL: "ContentProductCarouselComponent",
  MINI_BANNER: "miniBanner",
  QUARTER_GRID: "QuarterGridComponent",
  RECOMMENDATIONS: "ProductRecommendation",
  USPS: "Usps",
  MEDIUM_BANNER: "TwoColumnGrid",
  SMALL: "TwoColumnBanners",
  XS_BANNER: "fourGridBanners",
};
export const BREAKPOINTS = Object.keys(BRANDS_MAX_AMOUNT);
const componentsThatMightContainProductBanner = [
  "TwoColumnGrid",
  "TwoColumnBanners",
  "fourGridBanners",
];
const USPS_MAX_AMOUNT = {
  mobile: 1,
  tablet: 2,
  desktop: 4,
};

export type BrandsMaxAmountProps = keyof typeof BRANDS_MAX_AMOUNT;
export type UspsMaxAmountProps = keyof typeof USPS_MAX_AMOUNT;

export const setBrands = (
  brandsComponent: BrandsComponent | undefined,
  breakpoint: BrandsMaxAmountProps
): BrandsDataProps[] | null => {
  const itemsNumber = BRANDS_MAX_AMOUNT[breakpoint];

  if (isNotNullOrUndefined(brandsComponent) && "brands" in brandsComponent) {
    const filteredBrands: BrandData[] | undefined =
      brandsComponent.brands?.filter(
        (item) =>
          isNotNullOrUndefined(item.logo) &&
          isNotNullOrUndefined(item.brandCode) &&
          isNotNullOrUndefined(item.brandName)
      );
    if (isNotNullOrUndefined(filteredBrands) && filteredBrands.length > 0) {
      const brands: BrandsDataProps[] = [];
      filteredBrands.forEach((item, index) => {
        if (
          isNotNullOrUndefined(item.logo) &&
          isNotNullOrUndefined(item.brandCode) &&
          isNotNullOrUndefined(item.brandName) &&
          index < itemsNumber
        ) {
          brands.push({
            id: item.brandCode,
            name: item.brandName,
            code: item.brandCode,
            logo: item.logo,
          });
        }
      });
      return brands;
    }
  }
  return null;
};

export const setUsps = (
  usps: UspTeaser[],
  breakpoint: UspsMaxAmountProps
): UspTeaser[] => {
  const itemsNumber = USPS_MAX_AMOUNT[breakpoint];

  return usps.filter((_item, index) => index < itemsNumber);
};

export const createProductFetchInfo = (
  contentModules: HomePage20ContentModules | ContentModule[]
) =>
  contentModules.reduce(
    (fetchInfo, banner, index) => {
      if (!fetchInfo.isFetchPrioritised) {
        fetchInfo.isFetchPrioritised =
          componentsThatMightContainProductBanner.includes(banner._type) &&
          componentIndicesOnHomepageWithHighPrio.includes(index);
      }

      if (componentsThatMightContainProductBanner.includes(banner._type)) {
        const banners =
          (banner as TwoColumnGrid).twoColumnGrid ??
          (banner as TwoColumnBanners).twoColumnBanners ??
          (banner as FourGridBanners).fourGridBanners ??
          [];
        const products = banners
          .map(({ product }) => product)
          .filter(isProduct);

        const productCodes = products.reduce((codes, product) => {
          const { productCode } = product;

          if (isNotNullOrUndefined(productCode)) {
            codes.push(productCode);
          }

          return codes;
        }, [] as string[]);
        fetchInfo.productCodes = fetchInfo.productCodes.concat(productCodes);
      }
      return fetchInfo;
    },
    { isFetchPrioritised: false, productCodes: [] as string[] }
  );
